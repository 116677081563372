<template>
  <NewsEdit />
</template>

<script>
import NewsEdit from "@/components/news/NewsEdit"

export default {
  name: "NewsCreate",
  components: {
    NewsEdit,
  },
}
</script>

<style scoped></style>
